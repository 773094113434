import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/lib/axios";

import { ActivityType } from "../types";

export async function getActivityTypes(): Promise<ActivityType[]> {
    const { data } = await apiClient.get("", {
        params: {
            action: "liste_type_activite",
        },
    });
    return data.tab_liste_activite || [];
}

export function useGetActivityTypes() {
    const query = useQuery({
        queryKey: ["activity-types"],
        queryFn: getActivityTypes,
    });

    return query;
}
