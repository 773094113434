export enum ActivityTypeEnum {
    EXCURSION = "excursion",
    PRIVATISATION = "privatisation",
    LOCATION = "location",
}

export type ActivityType = {
    id: number;
    titre: ActivityTypeEnum;
};
