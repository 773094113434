import { ThemeOptions } from "@resamare/ui";

export const theme: ThemeOptions = {
    colorSchemes: {
        light: {
            palette: {
                background: {
                    default: "#fafbfb",
                },
            },
        },
    },
};
