/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";

import { AuthRoutes, Role } from "@/features/auth";
import { ProtectedRoute } from "@/lib/permissions";
import { SessionsRoutes } from "@/features/sessions";
import { LocationsRoutes } from "@/features/locations";
import { useScrollTop } from "@/hooks/useScrollTop";

export function AppRoutes() {
    useScrollTop();

    return (
        <Routes>
            <Route element={<ProtectedRoute allowedRoles={[Role.USER]} />}>
                <Route path="/" element={<Navigate to="/sessions" />} />
                <Route path="/sessions/*" element={<SessionsRoutes />} />
                <Route path="/locations/*" element={<LocationsRoutes />} />
            </Route>

            <Route element={<AuthRoutes />} path="/auth/*" />

            <Route element={<Navigate to="/" />} path="*" />
        </Routes>
    );
}
