/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { lazyImport } from "@resamare/ui";
import DefaultLayout from "@/components/Layout/DefaultLayout";

const { SessionsPage } = lazyImport(() => import("../pages"), "SessionsPage");
const { SessionDetailsPage } = lazyImport(() => import("../pages"), "SessionDetailsPage");

export function SessionsRoutes() {
    return (
        <Routes>
            <Route element={<DefaultLayout />}>
                <Route path="/" element={<SessionsPage />} />
                <Route path="/:sessionId" element={<SessionDetailsPage />} />
                <Route path="*" element={<Navigate to="." />} />
            </Route>
        </Routes>
    );
}
