/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { lazyImport } from "@resamare/ui";
import { ProtectedRoute } from "@/lib/permissions";
import { Role } from "../types";
import { useUser } from "@/lib/auth";

const { LoginPage } = lazyImport(() => import("../pages"), "LoginPage");
const { LogoutPage } = lazyImport(() => import("../pages"), "LogoutPage");

export function AuthRoutes() {
    const { data: user } = useUser();

    return (
        <Routes>
            {!user ? <Route path="/login" element={<LoginPage />} /> : null}

            <Route element={<ProtectedRoute allowedRoles={[Role.USER]} />}>
                <Route path="/logout" element={<LogoutPage />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
