import { Outlet } from "react-router-dom";

import { Suspense } from "react";
import { FullPageSpinner } from "@resamare/ui";
import Navigation from "@/components/Navigation/Navigation";

function DefaultLayout() {
    return (
        <Suspense fallback={<FullPageSpinner />}>
            <Outlet />
            <Navigation />
        </Suspense>
    );
}

export default DefaultLayout;
