/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { lazyImport } from "@resamare/ui";
import DefaultLayout from "@/components/Layout/DefaultLayout";

const { LocationsPage } = lazyImport(() => import("../pages"), "LocationsPage");
const { LocationDetailsPage } = lazyImport(() => import("../pages"), "LocationDetailsPage");

export function LocationsRoutes() {
    return (
        <Routes>
            <Route element={<DefaultLayout />}>
                <Route path="/" element={<LocationsPage />} />
                <Route path="/:locationId" element={<LocationDetailsPage />} />
                <Route path="*" element={<Navigate to="." />} />
            </Route>
        </Routes>
    );
}
