import { configureAuth } from "react-query-auth";

import { storage } from "@resamare/functions";
import { toast } from "@resamare/ui";
import { login, LoginCredentialsDTO, User, UserResponse } from "@/features/auth";
import { apiClient } from "./axios";

const USER_KEY = "user";
const TEN_DAYS = 10 * 86400000;

async function handleUserResponse(user?: UserResponse) {
    if (!user) {
        toast.error("Numéro de compte incorrect");
        return null;
    }
    apiClient.defaults.baseURL = user.url_web_service_client;
    storage.setWithExpiry(USER_KEY, user, TEN_DAYS);
    return user;
}

async function loginFn(data: LoginCredentialsDTO) {
    const response = await login(data);
    const user = await handleUserResponse(response);
    return user;
}

async function logoutFn() {
    storage.remove(USER_KEY);
}

async function userFn() {
    const user = storage.getWithExpiry<User>(USER_KEY);
    if (!user) return null;

    apiClient.defaults.baseURL = user.url_web_service_client;
    return user;
}

export const { useUser, useLogin, useRegister, useLogout, AuthLoader } = configureAuth({
    loginFn,
    logoutFn,

    userFn,
    registerFn: () => Promise.resolve(null),
});
