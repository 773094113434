import {
    BoatIcon,
    BottomNavigation,
    BottomNavigationItem,
    CheckListIcon,
    LogoutIcon,
} from "@resamare/ui";
import { ActivityTypeEnum, useGetActivityTypes } from "@/features/activityTypes";

function Navigation() {
    const activityTypesQuery = useGetActivityTypes();

    let ITEMS: BottomNavigationItem[] = [
        {
            icon: <CheckListIcon />,
            label: "Sessions",
            to: "/sessions",
        },

        {
            icon: <LogoutIcon />,
            label: "Déconnexion",
            to: "/auth/logout",
        },
    ];

    if (activityTypesQuery.data?.some((type) => type.titre === ActivityTypeEnum.LOCATION)) {
        ITEMS = [
            ITEMS[0],
            {
                icon: <BoatIcon />,
                label: "Locations",
                to: "/locations",
            },
            ITEMS[1],
        ];
    }

    return <BottomNavigation items={ITEMS} />;
}

export default Navigation;
