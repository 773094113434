export enum Role {
    GUEST = "guest",
    USER = "user",
}

export type User = {
    utilisateur: string;
    admin_id: string;
    url_web_service_client: string;
    qrcode: "0" | "1";
    roles: Role[];
};

export type UserResponse = User;
