import { DefaultOptions, QueryClient } from "@tanstack/react-query";

const defaultOptions: DefaultOptions = {
    queries: {
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 15 * 1000,
    },
};

export const queryClient = new QueryClient({ defaultOptions });
