import { PropsWithChildren } from "react";

import { initPermissions } from "@resamare/ui";
import { Role, User } from "@/features/auth";

import { useUser } from "./auth";

const {
    PermissionsProvider: BasePermissionsProvider,
    usePermissions,
    ProtectedRoute,
} = initPermissions<User | null | undefined, Role>();

function PermissionsProvider({ children }: PropsWithChildren) {
    const user = useUser();

    const roles = user.data ? [Role.USER, ...(user.data.roles || [])] : [Role.GUEST];

    return (
        <BasePermissionsProvider roles={roles} user={user.data}>
            {children}
        </BasePermissionsProvider>
    );
}

export { PermissionsProvider, usePermissions, ProtectedRoute };
