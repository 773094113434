import { extraErrorDataIntegration, init } from "@sentry/react";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;

export const initSentry = () => {
    init({
        dsn: SENTRY_DSN,
        integrations: [extraErrorDataIntegration()],
    });
};
