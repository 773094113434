import React, { useEffect, useRef, useState } from "react";

import {
    BottomNavigation as MuiBottomNavigation,
    BottomNavigationAction as MuiBottomNavigationAction,
} from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import styles from "./BottomNavigation.module.scss";
import { Typography } from "../../General";

export interface BottomNavigationItem {
    icon: React.ReactNode;
    label: string;
    to: string;
}

export interface BottomNavigationProps {
    items: BottomNavigationItem[];
}

export function BottomNavigation({ items }: BottomNavigationProps) {
    const { pathname } = useLocation();
    const menuRef = useRef<HTMLDivElement>(null);

    const [value, setValue] = useState(pathname);

    useEffect(() => {
        const newValue = `/${pathname.slice(1).split("/")[0]}`;
        setValue(newValue);
    }, [pathname]);

    useEffect(() => {
        const body = document.querySelector("body");

        if (body && menuRef.current) {
            body.style.paddingBottom = `${menuRef.current.offsetHeight / 16}rem`;
        }
    }, []);

    return (
        <MuiBottomNavigation ref={menuRef} className={styles["nav"]} showLabels value={value}>
            {items.map((item) => (
                <MuiBottomNavigationAction
                    key={item.to}
                    className={styles["nav__item"]}
                    component={Link}
                    icon={item.icon}
                    label={
                        <Typography variant={value === item.to ? "menu-label" : "text-xs"}>
                            {item.label}
                        </Typography>
                    }
                    to={item.to}
                    value={item.to}
                />
            ))}
        </MuiBottomNavigation>
    );
}
