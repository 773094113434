import { apiClient } from "@/lib/axios";

import { UserResponse } from "../types";
import { API_URL } from "@/config/constants";

export type LoginCredentialsDTO = {
    clientId: string;
    username: string;
    password: string;
};

export const login = (request: LoginCredentialsDTO): Promise<UserResponse> => {
    const { clientId, username, password } = request;

    return apiClient
        .get(`action.php`, {
            params: {
                action: "connexion",
                client_id: clientId,
                login: username,
                mdp: password,
            },
            baseURL: API_URL,
        })
        .then((response) => response.data);
};
