import { toast } from "@resamare/ui";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { storage } from "@resamare/functions";
import { User } from "@/features/auth";

export const apiClient = axios.create({});

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (config.params?.action === "connexion") {
        return config;
    }

    const user = storage.getWithExpiry<User>("user");

    if (!user) {
        window.location.replace("/auth/login");
        return config;
    }

    const newConfig: InternalAxiosRequestConfig = {
        ...config,
        params: { ...config.params, admin_id: user.admin_id },
    };

    return newConfig;
});

apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        if (
            response.data === "pas d'action"
            // || response.data === "action inconnu"
        ) {
            toast.error("Un problème est survenu");
            return Promise.reject(response);
        }
        if (response.data?.status === false) {
            const message = response.data?.message;
            toast.error(message || "Un problème est survenu");
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        const message = error.response?.data?.message || error.message;
        toast.error(message || "Un problème est survenu");
        return Promise.reject(error);
    },
);
